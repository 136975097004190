import React from 'react';

import Portfolio1 from '../../../assets/images/portfolio/1.jpg';
import Portfolio2 from '../../../assets/images/portfolio/2.jpg';
import Portfolio3 from '../../../assets/images/portfolio/3.jpg';
import Portfolio4 from '../../../assets/images/portfolio/4.jpg';
import Portfolio5 from '../../../assets/images/portfolio/5.jpg';
import Portfolio6 from '../../../assets/images/portfolio/6.jpg';
import Portfolio7 from '../../../assets/images/portfolio/7.jpg';
import Portfolio8 from '../../../assets/images/portfolio/8.jpg';
import Portfolio9 from '../../../assets/images/portfolio/9.jpg';
import Portfolio10 from '../../../assets/images/portfolio/10.jpg';
import Portfolio11 from '../../../assets/images/portfolio/11.jpg';
import Portfolio12 from '../../../assets/images/portfolio/12.jpg';
import Portfolio13 from '../../../assets/images/portfolio/13.jpg';
import Portfolio14 from '../../../assets/images/portfolio/14.jpg';
import Portfolio15 from '../../../assets/images/portfolio/15.jpg';
import Portfolio16 from '../../../assets/images/portfolio/18.jpg';
import Portfolio17 from '../../../assets/images/portfolio/19.jpg';
import Portfolio18 from '../../../assets/images/portfolio/20.jpg';
import Portfolio19 from '../../../assets/images/portfolio/21.jpg';

import './images.scss';

const ImagesPortifolio = () => (
  <>
    <section id="portfolio" className="container">
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio1} />
          <p>Estampa em Suplex para produção de biquínis.</p>
        </div>
        <div className="item">
          <img src={Portfolio2} />
          <p>Estampa corrida em Suplex para produção de biquínis.</p>
        </div>
        <div className="item">
          <img src={Portfolio3} />
          <p>Estampa em Tactel para produção de camisas.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio4} />
          <p>Estampa em Tactel elastano para produção de camisas.</p>
        </div>
        <div className="item">
          <img src={Portfolio5} />
          <p>Estampa em Tactel elastano para produção de camisas.</p>
        </div>
        <div className="item">
          <img src={Portfolio6} />
          <p>Estampa em Suede para revestimento de móveis.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio7} />
          <p>Estampa em Tactel para produção de painéis infantis.</p>
        </div>
        <div className="item">
          <img src={Portfolio8} />
          <p>Estampa em Tactel para produção de painéis infantis.</p>
        </div>
        <div className="item">
          <img src={Portfolio9} />
          <p>Estampa Tye Die em malha poliéster para produção de pijamas.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio10} />
          <p>Estampa em Tactel elastano para produção de camisas.</p>
        </div>
        <div className="item">
          <img src={Portfolio11} />
          <p>Estampa em Oxford para produção de painéis.</p>
        </div>
        <div className="item">
          <img src={Portfolio12} />
          <p>Estampa em Oxford para produção de painéis.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio13} />
          <p>Estampa em Oxford para produção de painéis.</p>
        </div>
        <div className="item">
          <img src={Portfolio14} />
          <p>Estampa em Oxford para produção de painéis.</p>
        </div>
        <div className="item">
          <img src={Portfolio15} />
          <p>Estampa corrida em PET para produção de máscaras.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio16} />
          <p>Estampa em malha poliéster para produção de pijamas.</p>
        </div>
        <div className="item">
          <img src={Portfolio17} />
          <p>Estampa em malha poliéster para produção de pijamas.</p>
        </div>
        <div className="item">
          <img src={Portfolio18} />
          <p>Estampa em malha poliéster para produção de pijamas.</p>
        </div>
      </div>
      <div className="items-wrapper">
        <div className="item">
          <img src={Portfolio19} />
          <p>Estampa em malha poliéster para produção de pijamas.</p>
        </div>
      </div>
    </section>
  </>
);

export default ImagesPortifolio;
