import React from 'react';

import { SEO } from '../../components/seo';

import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

import ImagesPortifolio from './images-portifolio';

import '../../reset.css';

const Portifolio = () => (
  <>
    <SEO title="Portfólio" />
    <Header title="Portfólio" />
    <ImagesPortifolio />
    <Footer />
  </>
);

export default Portifolio;
